import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

const fieldBudgetDetail = `
	id
	type
	estimatedFinalCost
	projectId
	vatPercent
	vatPaidBack
	contentType
	category
	description
	departmentName
	appChoice
	appName
	isSum
	parentId
	costCenter
	costCenterA
	costCenterB
	costCenterC
	comment
	doconomyCategory
	coTwoCategory
	startOfPeriodValue
	initialBudget
	createdAt
`;

export const getCategoryByFunctionId = async (functionId = 0) => {
	try {
		const {
			data: { GetBudgetDetailFunctionCategory }
		} = await apollo.query({
			query: gql`
				query ($functionId: ID!) {
					GetBudgetDetailFunctionCategory(Function: $functionId) {
						id
						createdAt
						updatedAt
						projectId
						department
						category
						type
						contentType
						masterCategory
						doconomyCategory
						estimatedFinalCost
						vatPercent
						vatPaidBack
						appChoice
						appName
						description
						costCenter
						costCenterA
						costCenterB
						costCenterC
						comment
						coTwoPerCurrency
						coTwoCategory
						parentId
						isSum
						importId
						importParentId
						departmentName
						hasChild
					}
				}
			`,
			variables: {
				functionId: parseInt(functionId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetBudgetDetailFunctionCategory;
	} catch (error) {
		console.log({ error });
	}
};

export const GetBudgetCategoryList = async (DepartmentNumber = 0) => {
	try {
		const {
			data: { GetBudgetCategoryList }
		} = await apollo.query({
			query: gql`
				query ($DepartmentNumber: Int) {
					GetBudgetCategoryList(DepartmentNumber: $DepartmentNumber) {
						${fieldBudgetDetail}
					}
				}
			`,
			variables: {
				DepartmentNumber: parseInt(DepartmentNumber, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetBudgetCategoryList;
	} catch (error) {
		console.log(error);
	}
};

export const CheckBudgetDetailExpenseSalary = async (BudgetDetailId = 0) => {
	try {
		const {
			data: { CheckBudgetDetailExpenseSalary }
		} = await apollo.query({
			query: gql`
				query ($BudgetDetailId: ID!) {
					CheckBudgetDetailExpenseSalary(BudgetDetailId: $BudgetDetailId)
				}
			`,
			variables: {
				BudgetDetailId: parseInt(BudgetDetailId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return CheckBudgetDetailExpenseSalary;
	} catch (error) {
		console.log(error);
	}
};

export const updateBudgetDetail = async (budgetDetailId, inputBudgetDetail, updArgument = null, updChildren = false) => {
	try {
		const MUTATION_UPDATE_BUDGET_DETAIL = gql`
			mutation ($budgetDetailId: ID!, $inputBudgetDetail: BudgetDetailInput!, $updArgument: Int, $updChildren: Boolean) {
				UpdBudgetDetail(BudgetDetailId: $budgetDetailId, UpdatedBuDe: $inputBudgetDetail, UpdArgument: $updArgument, UpdChildren: $updChildren) {
					${fieldBudgetDetail}
				}
			}
		`;

		let variables = {
			budgetDetailId: parseInt(budgetDetailId, 10),
			inputBudgetDetail,
			updArgument,
			updChildren
		};

		if (isNil(updArgument)) {
			delete variables.updArgument;
		}

		const {
			data: { UpdBudgetDetail }
		} = await apollo.mutate({
			mutation: MUTATION_UPDATE_BUDGET_DETAIL,
			variables
		});

		return UpdBudgetDetail;
	} catch (error) {
		console.log(error);
	}
};

export const addBudgetDetail = async (departmentNumber, parentId = 0, budgetDetailInput) => {
	try {
		const MUTATION_ADD_BUDGET_DETAIL = gql`
			mutation ($DepartmentNumber: Int!, $ParentId: ID, $BudgetDetailData: BudgetDetailInput) {
				NewBudgetDetail(DepartmentNumber: $DepartmentNumber, ParentId: $ParentId, BudgetDetailData: $BudgetDetailData) {
					${fieldBudgetDetail}
				}
			}
		`;

		const {
			data: { NewBudgetDetail }
		} = await apollo.mutate({
			mutation: MUTATION_ADD_BUDGET_DETAIL,
			variables: {
				DepartmentNumber: parseInt(departmentNumber, 10),
				ParentId: parseInt(parentId, 10),
				BudgetDetailData: budgetDetailInput
			}
		});

		return NewBudgetDetail;
	} catch (e) {
		console.log(e);
	}
};

export const getBudgetDetail = async (budgetDetailId = 0) => {
	const query = gql`
    query($budgetDetailId: ID!) {
      GetBudgetDetail(BudgetDetailId: $budgetDetailId) {
        ${fieldBudgetDetail}
      }
    }
  `;

	const {
		data: { GetBudgetDetail }
	} = await apollo.query({
		query,
		variables: {
			budgetDetailId: +budgetDetailId
		},
		fetchPolicy: 'no-cache'
	});

	return GetBudgetDetail;
};
